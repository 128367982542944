/* ========== HEADER ==========*/

header {
    background-color: #E8FACD;
}

#header-content {
    height: 100px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
}

.container-fluid {
    max-width: 1200px;
    padding: 0 0.5rem;
}

h1 {
    font-size: 2rem;
}

h2, h3, h4 {
    font-family: 'Inter', sans-serif;
    font-size: 1.25rem;
    font-weight: bold;
}

.logo-link {
    width: 140px;
}

nav {
    display: flex;
    gap: 2rem;
}

nav a {
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    color: #006723;
    font-size: 1.2rem;
    line-height: 100px;
}

nav a:hover {
    color: #006723;
}

button  {
    background-color: #70b603;
    border: none;
    padding: 10px;
    border-radius: 8px;
    color: white;
}

.button-primary {
    background-color: #70b603;
    border: none;
    padding: 10px;
    border-radius: 8px;
    color: white;
}

.button-primary a, .button-primary a:hover {
    color:white;
}

.button-primary:hover {
    background-color: #518400;
    color: white;
}

.button-secondary {
    background-color: transparent;
    border: 1px solid #518400;
    padding: 10px;
    border-radius: 8px;
    color: #518400;
}

.button-secondary:hover {
    background-color: #e5ffbb;
    color: #518400;
}

#upload_widget {
    background-color: transparent;
    border: 1px solid #518400;
    padding: 10px;
    border-radius: 8px;
    color: #518400;
    width: fit-content;
    height: 2.6rem;
    line-height: 1;
    flex-basis: 28%;
    margin-bottom: 1rem;
}

#upload_widget:hover {
    background-color: #e5ffbb;
    color: #518400;
    box-shadow: none;
}

#photos-upload {
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
}

button a {
    text-decoration: none;
    color: white;
}

#logout-btn, #login-btn, #signup-btn {
    text-decoration: none;
    color: white;
    
}

#login {
    display:flex;
    align-items: center;
    gap: 2rem;
}

#top-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-bottom: 1.5rem;
    width: 100%;
}

#top-search button {
    height: 40px;
    background-color: #70b603;
    border: none;
}

#main-content {
    max-width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 13rem);
    padding: 2rem 0.5rem;
}

#map-veg {
    display: flex;
    max-width: 1200px;
}

#veggie-sidebar {
    flex-basis: 25%;
    height: 500px;
    overflow-y: scroll;
}

.veggie-actions {
    display: flex;
    gap: 1rem;
}

#leaflet-map {
    flex-basis: 75%;
}

.veggie-list-item {
    display: flex;
    padding: 1rem 0;
    border-bottom: 1px solid lightgrey;
    align-items: center;
    gap: 0.5rem;
}

.veggie-list-item:hover {
    background-color: #EEF3F9
}

.radios .radio{
    cursor:pointer
}

.radios label {
    width: 100%;
}

.radios input[type=radio]{
    display:none
}

.radios input[type=radio]:checked + .radio{
    background-color:#E8FACD
}

.veggie-list-item img {
    height: 50px;
    padding-right: 5px;
}

.veggie-list-item h4  {
    margin-bottom: 0;    
}

.veggie-list-item p  {
    margin: 0;
    color: #767676;
}

#veggie-div {
    height: 500px;
    overflow-y:scroll;
}

#add-veggie-form {
    display: flex;
    flex-direction: column;
}


/* ========== LANDING PAGE ==========*/

#landing-main {
    min-height: calc(100vh - 13rem);
}

.landing-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 0;
    text-align: center;
}

.landing-section h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.landing-section p {
    max-width: 700px;
    margin: 0 auto;
    font-size: 1.25rem;
}


/* ========== MY VEGGIES ==========*/

.my-veggies-list {
    display:flex;
    flex-direction: column;
    gap: 1rem;
}

.single-veggie {
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    box-shadow: 1px 2px 5px #dedede;
}

.single-veggie img {
    width: 60px;
}

.veggie-meta {
    display: flex;
    gap: 1rem;
}

.veggie-meta h4 {
    display:inline-block;
}

.veggie-meta .timestamp {
    color: #767676;
    margin-left: 0.5rem;
    display:inline-block;
}


/* ========== REQUESTS & RESPONSES ==========*/

.request-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.single-request {
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: 1px 2px 5px #dedede;
}

.single-request-header {
    padding: 1rem;
}

.single-request img {
    width: 60px;
}

.single-request h2 {
    display: inline-block;
    margin-left: 1rem;
}

.single-response {
    border-bottom: 1px solid #ddd;
    padding: 0.5rem 1rem;
}

.sender-meta {
    margin-bottom: 0;
    flex-shrink: 0;
}

.message-timestamp {
    color: #767676;
    /* margin-left: 0.5rem; */
}

.request-meta {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.snippet {
    margin: 0;
}

.conversation-icon {
    width: 50px;
}

.conversation-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.response-form {
    display:flex;
    gap: 1rem;
    padding: 1rem;
}

.response-form textarea {
    border: 1px solid #ccc;
    width: 500px;
    padding: 1rem;
    border-radius: 8px;
}


/* ========== FOOTER ==========*/

footer {
    border-top: 1px solid #eee;
    padding: 1rem;
}

#footer-content{
    max-width: 1200px;
    margin: 0 auto;
    color: #767676;
}

#footer-content a {
    color: #222;
}




/* ========== MODALS ==========*/

#signup-modal .navbar-nav {
    flex-direction: row;
    gap: 2rem;
}

#signup-modal .nav-item .nav-link {
    background-color: transparent;
    color:#006723;
}

#signup-modal .nav-item .nav-link.active {
    background-color: transparent;
    color:#006723;
    border-radius: 0;
    box-sizing: border-box;
    border-bottom: 5px solid #70b603;
}


#request-veggie-form textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
}

#add-veggie-form input,
#add-veggie-form textarea,
#edit-veggie-form input,
#edit-veggie-form textarea,
#login-form input,
#signup-form input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.5rem;
    margin-bottom: 1rem;
}

#add-veggie-form select {
    border-radius: 8px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    background-color: #eee;
}





/* ========== MOBILE ==========*/

@media screen and (max-width: 768px) {
    nav a {
        font-size: 1.2rem;
        line-height: 2rem;
    }

    #nav-links a {
        width:100%;
        flex-basis:100%;
        text-align: center;
    }

    #auth-buttons {
        margin-top: 4rem;
    }

    #map-veg {
        flex-direction: column-reverse;

    }
    
  }